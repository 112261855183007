<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="350" content-class="rounded-xl">
      <v-card class="rounded-xl">
        <v-card-title class="text-h5">
          {{ $t('delivery.addFormOfTransport') }}
        </v-card-title>
        <v-card-text class="mt-4">
          <v-form ref="addForm" v-model="valid">
            <v-text-field v-model="name" dense :label="$t('delivery.name')" class="py-2" :rules="required"></v-text-field>
            <v-text-field v-model.number="price" dense :label="$t('delivery.price')" class="py-2" type="number" :rules="priceRule"></v-text-field>
            <v-file-input v-model="icon" :label="$t('delivery.icon')" accept="image" :rules="required"></v-file-input>
            <v-text-field v-model.number="leadTime" dense :label="$t('delivery.leadTime') + ' ' + $t('delivery.inHours')" class="py-2" type="number" :rules="required"></v-text-field>
            <v-text-field v-model.number="deliveryTime" dense :label="$t('delivery.deliveryTime') + ' ' + $t('delivery.inDays')" class="py-2" type="number" :rules="required"></v-text-field>
            <v-text-field v-model="deliveryDescription" dense :label="$t('delivery.additionalDeliveryDescription')" class="py-2" maxlength="70"></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="add" :disabled="loading" :loading="loading" rounded>
            {{ $t('buttons.add') }}
          </v-btn>
          <v-btn @click="close" :disabled="loading" rounded>
            {{ $t('buttons.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import Service from '@/services'
export default {
  name: 'AddMethod',
  data () {
    return {
      dialog: false,
      valid: false,
      name: '',
      price: null,
      icon: null,
      leadTime: '',
      deliveryTime: '',
      deliveryDescription: '',
      loading: false,
      required: [(v) => !!v || this.$t('validation.fieldRequired')],
      priceRule: [v => !!v || this.$t('validation.fieldRequired')]
    }
  },
  methods: {
    show () {
      this.dialog = true
    },
    async add () {
      this.$refs.addForm.validate()

      if (!this.valid) return

      this.loading = true

      const formData = new FormData()
      formData.append('Data',
        JSON.stringify({
          Name: this.name,
          Price: this.price,
          LeadTime: Math.abs(Math.trunc(this.leadTime)),
          DeliveryTime: Math.abs(Math.trunc(this.deliveryTime)),
          DeliveryDescription: this.deliveryDescription
        })
      )

      if (this.icon) {
        formData.append('Icon', this.icon)
      }

      await Service.post('/ShippingMethods', formData)

      this.loading = false
      this.$refs.addForm.reset()

      this.$emit('refresh')

      this.dialog = false
    },
    close () {
      this.$refs.addForm.reset()
      this.dialog = false
    }
  }
}
</script>
